import { css } from '@emotion/react';
import { grid } from '@styles/grid.styes';
import { breakpoints } from '@styles/variables.styles';
import { typo } from '@styles/typo.styles';

const base = css`
  ${grid.basic6col};
  min-height: 100vh;
  color: var(--color-text-6);
  grid-auto-rows: auto;

  @media ${breakpoints.fromSml} {
    ${grid.basic12col};
  }

  @media ${breakpoints.fromLrg} {
    ${grid.basic50col};
  } ;
`;

const contentWrapper = css`
  grid-column: 1 / span 6;

  @media ${breakpoints.fromSml} {
    grid-column: 2 / span 10;
  }

  @media ${breakpoints.fromLrg} {
    grid-column: 15 / span 22;
  } ;
`;

const sectionStyle = css`
  margin-bottom: 34px;
  max-width: 100%;

  @media ${breakpoints.fromLrg} {
    margin-bottom: 54px;
  } ;
`;

const title = css`
  ${typo.from68to36};
  color: var(--color-text-5);
  font-weight: 400;
  margin: 48px 0 40px;
  letter-spacing: -0.03em;

  @media ${breakpoints.fromLrg} {
    margin: 80px 0 68px;
  } ;
`;

const subtitle = css`
  ${typo.from20to16};
  color: var(--color-text-5);
  font-weight: 400;
  margin-bottom: 16px;
`;

const content = css`
  ${typo.from16to14};
  color: var(--color-text-4);
  font-weight: 400;
  margin-bottom: 14px;
`;

const sectionThreeContent = css`
  ${typo.from16to14};
  color: var(--color-text-4);
  font-weight: 400;
  margin-bottom: 28px;
`;

const liStyle = css`
  padding: 14px 0;
`;

export const termsOfServiceStyles = {
  base,
  contentWrapper,
  sectionStyle,
  title,
  subtitle,
  content,
  sectionThreeContent,
  liStyle,
};
