import React from 'react';
import { FormattedMessage } from 'react-intl';
import { termsOfServiceStyles as styles } from './TermsOfService.styles';

export const TermsOfService: React.FC = () => {
  return (
    <div css={styles.base}>
      <div css={styles.contentWrapper}>
        <h1 css={styles.title}>
          <FormattedMessage id="tos.title" />
        </h1>
        <section css={styles.sectionStyle}>
          <h2 css={styles.subtitle}>
            <FormattedMessage id="tos.section-one.subtitle" />
          </h2>
          <p css={styles.content}>
            <FormattedMessage id="tos.section-one.paragraph" />
          </p>
          <ul css={styles.content}>
            <li css={styles.liStyle}>
              <FormattedMessage id="tos.section-one.li-one" />
            </li>
            <li css={styles.liStyle}>
              <FormattedMessage id="tos.section-one.li-two" />
            </li>
            <li css={styles.liStyle}>
              <FormattedMessage id="tos.section-one.li-three" />
            </li>
            <li css={styles.liStyle}>
              <FormattedMessage id="tos.section-one.li-four" />
            </li>
            <li css={styles.liStyle}>
              <FormattedMessage id="tos.section-one.li-five" />
            </li>
            <li css={styles.liStyle}>
              <FormattedMessage id="tos.section-one.li-six" />
            </li>
            <li css={styles.liStyle}>
              <FormattedMessage id="tos.section-one.li-seven" />
            </li>

            <li css={styles.liStyle}>
              <FormattedMessage id="tos.section-one.li-eight" />
            </li>
            <li css={styles.liStyle}>
              <FormattedMessage id="tos.section-one.li-nine" />
            </li>
          </ul>
        </section>
        <section css={styles.sectionStyle}>
          <h2 css={styles.subtitle}>
            <FormattedMessage id="tos.section-two.subtitle" />
          </h2>
          <p css={styles.content}>
            <FormattedMessage id="tos.section-two.paragraph-one" />
          </p>
          <ul css={styles.content}>
            <li css={styles.liStyle}>
              <FormattedMessage id="tos.section-two.li-one" />
            </li>
            <li css={styles.liStyle}>
              <FormattedMessage id="tos.section-two.li-two" />
            </li>
          </ul>
          <p css={styles.content}>
            <FormattedMessage id="tos.section-two.paragraph-two" />
          </p>
          <p css={styles.content}>
            <FormattedMessage id="tos.section-two.paragraph-three" />
          </p>
        </section>
        <section css={styles.sectionStyle}>
          <h2 css={styles.subtitle}>
            <FormattedMessage id="tos.section-three.subtitle" />
          </h2>
          <p css={styles.sectionThreeContent}>
            <FormattedMessage id="tos.section-three.paragraph-one" />
          </p>
          <p css={styles.sectionThreeContent}>
            <FormattedMessage id="tos.section-three.paragraph-two" />
          </p>
          <p css={styles.sectionThreeContent}>
            <FormattedMessage id="tos.section-three.paragraph-three" />
          </p>
          <p css={styles.sectionThreeContent}>
            <FormattedMessage id="tos.section-three.paragraph-four" />
          </p>
          <p css={styles.sectionThreeContent}>
            <FormattedMessage id="tos.section-three.paragraph-five" />
          </p>
          <p css={styles.sectionThreeContent}>
            <FormattedMessage id="tos.section-three.paragraph-six" />
          </p>
        </section>
        <section css={styles.sectionStyle}>
          <h2 css={styles.subtitle}>
            <FormattedMessage id="tos.section-four.subtitle" />
          </h2>
          <p css={styles.content}>
            <FormattedMessage id="tos.section-four.paragraph-one" />
          </p>
          <ul css={styles.content}>
            <li>
              <FormattedMessage id="tos.section-four.li-one" />
            </li>
            <li>
              <FormattedMessage id="tos.section-four.li-two" />
            </li>
            <li>
              <FormattedMessage id="tos.section-four.li-three" />
            </li>
            <li>
              <FormattedMessage id="tos.section-four.li-four" />
            </li>
            <li>
              <FormattedMessage id="tos.section-four.li-five" />
            </li>
            <li>
              <FormattedMessage id="tos.section-four.li-six" />
            </li>
            <li>
              <FormattedMessage id="tos.section-four.li-seven" />
            </li>
            <li css={styles.liStyle}>
              <FormattedMessage id="tos.section-four.li-seven-1" />
            </li>
          </ul>
          <p css={styles.content}>
            <FormattedMessage id="tos.section-four.paragraph-two" />
          </p>
          <ul css={styles.content}>
            <li>
              <FormattedMessage id="tos.section-four.li-eight" />
            </li>
            <li>
              <FormattedMessage id="tos.section-four.li-nine" />
            </li>
            <li>
              <FormattedMessage id="tos.section-four.li-ten" />
            </li>
            <li>
              <FormattedMessage id="tos.section-four.li-eleven" />
            </li>
            <li>
              <FormattedMessage id="tos.section-four.li-twelve" />
            </li>
          </ul>
        </section>
        <section css={styles.sectionStyle}>
          <h2 css={styles.subtitle}>
            <FormattedMessage id="tos.section-five.subtitle" />
          </h2>
          <p css={styles.content}>
            <FormattedMessage id="tos.section-five.paragraph-one" />
          </p>
          <p css={styles.content}>
            <FormattedMessage id="tos.section-five.span-one" />
          </p>
          <p css={styles.content}>
            <FormattedMessage id="tos.section-five.span-two" />
          </p>
          <p css={styles.content}>
            <FormattedMessage id="tos.section-five.paragraph-two" />
          </p>
          <p css={styles.content}>
            <FormattedMessage id="tos.section-five.paragraph-three" />
          </p>
          <p css={styles.content}>
            <FormattedMessage id="tos.section-five.paragraph-four" />
          </p>
          <p css={styles.content}>
            <FormattedMessage id="tos.section-five.paragraph-five" />
          </p>
        </section>
        <section css={styles.sectionStyle}>
          <h2 css={styles.subtitle}>
            <FormattedMessage id="tos.section-six.subtitle" />
          </h2>
          <p css={styles.content}>
            <FormattedMessage id="tos.section-six.paragraph-one" />
          </p>
        </section>
        <section css={styles.sectionStyle}>
          <h2 css={styles.subtitle}>
            <FormattedMessage id="tos.section-seven.subtitle" />
          </h2>
          <p css={styles.content}>
            <FormattedMessage id="tos.section-seven.paragraph" />
          </p>
        </section>
        <section css={styles.sectionStyle}>
          <h2 css={styles.subtitle}>
            <FormattedMessage id="tos.section-eight.subtitle" />
          </h2>
          <p css={styles.content}>
            <FormattedMessage id="tos.section-eight.paragraph-one" />
          </p>
          <p css={styles.content}>
            <FormattedMessage id="tos.section-eight.paragraph-two" />
          </p>
        </section>
        <section css={styles.sectionStyle}>
          <h2 css={styles.subtitle}>
            <FormattedMessage id="tos.section-nine.subtitle" />
          </h2>
          <p css={styles.content}>
            <FormattedMessage id="tos.section-nine.paragraph-one" />
          </p>
          <p css={styles.content}>
            <FormattedMessage id="tos.section-nine.paragraph-two" />
          </p>
        </section>
        <section css={styles.sectionStyle}>
          <h2 css={styles.subtitle}>
            <FormattedMessage id="tos.section-ten.subtitle" />
          </h2>
          <p css={styles.content}>
            <FormattedMessage id="tos.section-ten.paragraph-one" />
          </p>
        </section>
        <section css={styles.sectionStyle}>
          <h2 css={styles.subtitle}>
            <FormattedMessage id="tos.section-eleven.subtitle" />
          </h2>
          <p css={styles.content}>
            <FormattedMessage id="tos.section-eleven.paragraph-one" />
          </p>
        </section>
      </div>
    </div>
  );
};
